<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-layout wrap>
            <v-flex xs12>
                <v-text-field label="Nome" v-model="item.name" :rules="[rules.required]" />
            </v-flex>
            <v-flex xs12>
                <v-autocomplete v-model="item.category" :items="category" :rules="[rules.required]" item-value="key" item-text="description" label="Tipo de grupo" class="my-0 py-0"
                    deletable-chips small-chips />
            </v-flex>
            <v-flex xs12>
                <v-autocomplete v-model="item.users" :rules="[rules.required]" :items="userList" item-value="id" item-text="email" label="Usuários" deletable-chips multiple
                    small-chips />
            </v-flex>
            <v-flex xs12>
                <v-autocomplete v-model="item.chargers" :rules="[rules.required]" :items="chargerList" item-value="id" item-text="name" label="Carregadores" deletable-chips
                    multiple small-chips />
            </v-flex>

            <!-- TODO: REMOVE THIS AFTER MIGRATION -->
            <v-flex xs12 v-if="item.category == 'CONDOMINIUM'">
                <v-alert icon="shield" prominent text type="error"> Grupo de condomínio não é mais utilizado. </v-alert>
            </v-flex>

            <!-- <v-flex xs12>
                    <v-btn :loading="lock" :disabled="!valid || item.category == 'CONDOMINIUM'" color="success" @click="validate">Salvar</v-btn>
                </v-flex> -->
            <v-flex xs12>
                <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate" style="width: 100%;">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            userList: [],
            chargerList: [],
            itemClean: {
                name: "",
                idState: "",
            },
            item: { ...this.itemClean },
            category: [
                { key: "CONDOMINIUM", description: "Condomínio" }, // TODO: REMOVE THIS AFTER MIGRATION
                { key: "PARTNER_CAR", description: "Parceiro carro" },
                { key: "INTEGRATION", description: "Integração" },
                { key: "PARTNER_BIKE", description: "Parceiro bike" },
            ],
        };
    },

    mounted() {
        this.$http
            .get("api/v2/user")
            .then((result) => {
                this.userList = result;
            })
            .catch(() => {
                this.userList = [];
            });
        this.$http
            .get("api/v2/charger")
            .then((result) => {
                this.chargerList = result;
            })
            .catch(() => {
                this.chargerList = [];
            });
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                if (newVal == null) {
                    this.item = { ...this.itemClean };
                } else {
                    this.item = { ...newVal };
                }
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },
};
</script>